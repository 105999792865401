<template>
    <div class="renew">
        <div class="container">
            <div class="virtual-tryon">
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <img
                            :src="formatAssetUrl('images/homepage/mobile-virtual-2.jpg')"
                            alt="virtual-tryon"
                            class="mobile-renew-img"
                        />
                    </div>
                    <div class="col-md-6 col-sm-12 text-center renew-order">
                        <h3>Virtual Try-On</h3>
                        <p>
                            Our virtual try-on technology lets you try on frames from the comfort of your couch
                        </p>
                        <nuxt-link to="/glasses" class="btn btn-primary">Try it now</nuxt-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import formatImageMixin from '@/mixins/image-url-formatter';
export default {
    mixins: [formatImageMixin]
};
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/breakpoints.scss';

.renew {
    margin-top: 0px;
    margin-bottom: 48px;
    .virtual-tryon {
        .btn {
            display: flex;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            justify-content: center;
            font-weight: bold;
        }
    }
}

@media (max-width: $breakpoint2) {
    .renew {
        margin-bottom: 20px;
        .virtual-tryon {
            margin-top: 10px;
        }
    }
}
</style>
