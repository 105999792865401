<template>
    <client-only>
        <div class="mobile-trustpilot-homepage">
            <!-- TrustBox widget - Mini -->
            <div id="homepage-trustbox" class="trustpilot-widget" data-locale="en-US" data-template-id="53aa8807dec7e10d38f59f32" data-businessunit-id="550884280000ff00057e1e9e" data-style-height="150px" data-style-width="100%" data-theme="light">
            <a href="https://www.trustpilot.com/review/lensdirect.com" target="_blank" rel="noopener">Trustpilot</a>
            </div>
            <!-- End TrustBox widget -->
        </div>
    </client-only>
</template>

<script>
import trustpilotMixin from '@/mixins/trustpilot-mixin';

export default {
    mixins: [trustpilotMixin],
    mounted() {
        this.loadTrustpilotWidget('homepage-trustbox');
    }
}
</script>

<style lang="scss" scoped>
    @import '~/assets/scss/variables/breakpoints.scss';

    .mobile-trustpilot-homepage {
        display: none;
    }

    @media (max-width: $breakpoint3) {
        .mobile-trustpilot-homepage {
            display: block;
            padding-top: 40px;
            padding-bottom: 10px;
        }
    }
</style>
