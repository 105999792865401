<template>
    <div class="filterBrand homepage">
        <div class="partner-slides owl-carousel owl-theme">
            <ssr-carousel
                :loop="true"
                :slides-per-page="8"
                :responsive="[
                    {
                        maxWidth: 1025,
                        slidesPerPage: 3,
                        peekRight: 50
                    }
                ]"
            >
                <div class="slide" v-for="(brand, index) in brands" :key="index" :index="index">
                    <nuxt-link :to="'/' + brand.slug" class="brand">
                        <div class="d-flex align-items-center justify-content-center h-100">
                            <img :src="formatAssetUrl('images/' + brand.image)" :alt="brand.alt" :class="brand.slug" />
                        </div>
                    </nuxt-link>
                </div>
                <div class="slide">
                    <nuxt-link to="/contact-lenses" class="brand">
                        <div class="d-flex align-items-center justify-content-center h-100 all-brands">
                            All Brands
                        </div>
                    </nuxt-link>
                </div>
            </ssr-carousel>
        </div>
    </div>
</template>

<script>
import formatImageMixin from '@/mixins/image-url-formatter';

export default {
    mixins: [formatImageMixin],
    name: 'Brand',
    data() {
        return {
            brands: [
                { name: 'Acuvue', alt: 'Acuvue', image: 'brand-logos/acuvue-black.png', slug: 'acuvue-contacts' },
                { name: 'Air Optix', alt: 'Air Optix', image: 'brand-logos/air-optix-black.png', slug: 'air-optix' },
                { name: 'Dailies', alt: 'Dailies', image: 'brand-logos/dailies-black.png', slug: 'dailies' },
                { name: 'Clariti', alt: 'Clariti', image: 'brand-logos/clariti-black.png', slug: 'clariti' },
                { name: 'Biofinity', alt: 'Biofinity', image: 'brand-logos/biofinity-black.png', slug: 'biofinity' },
                {
                    name: 'Bausch & Lomb',
                    alt: 'Bausch & Lomb',
                    image: 'brand-logos/bausch-lomb-black.png',
                    slug: 'bausch-and-lomb'
                },
                { name: 'Precision 1', alt: 'Precision 1', image: 'brand-logos/precision1-black.svg', slug: 'precision-1' }
            ]
        };
    }
};
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/breakpoints.scss';
@import '~/assets/scss/variables/color.scss';

.filterBrand {
    .brand {
        border: solid $color-grey-border 1px;
        box-shadow: none;
        &:hover {
            box-shadow: 0px 12px 36px #00000014;
            > * {
                text-decoration: underline;
            }
        }
        .all-brands {
            font-size: 18px;
            font-weight: bold;
        }
        img {
            max-height: 24px;
            &.air-optix {
                max-height: 32px;
            }
            &.biofinity {
                max-height: 30px;
                margin-top: 4px;
            }
            &.freshlook {
                max-height: 32px;
            }
        }
    }
}

@media (max-width: $desktopBreakpoint3) {
    .filterBrand {
        .brand {
            img {
                &.air-optix {
                    max-height: 28px;
                }
                &.biofinity {
                    max-height: 24px;
                }
                &.freshlook {
                    max-height: 30px;
                }
                &.dailies {
                    max-height: 18px;
                }
                &.acuvue-contacts {
                    max-height: 19px;
                }
                &.clariti {
                    max-height: 20px;
                }
            }
        }
    }
}

@media (max-width: $breakpoint3) {
    .filterBrand {
        padding: 0px 20px;
        padding-right: 0px;
        border-top: solid $color-grey-border 1px;
        .brand {
            border: 0px;
        }
    }
}
</style>
