var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"filterBrand homepage"},[_c('div',{staticClass:"partner-slides owl-carousel owl-theme"},[_c('ssr-carousel',{attrs:{"loop":true,"slides-per-page":8,"responsive":[
                {
                    maxWidth: 1025,
                    slidesPerPage: 3,
                    peekRight: 50
                }
            ]}},[_vm._l((_vm.brands),function(brand,index){return _c('div',{key:index,staticClass:"slide",attrs:{"index":index}},[_c('nuxt-link',{staticClass:"brand",attrs:{"to":'/' + brand.slug}},[_c('div',{staticClass:"d-flex align-items-center justify-content-center h-100"},[_c('img',{class:brand.slug,attrs:{"src":_vm.formatAssetUrl('images/' + brand.image),"alt":brand.alt}})])])],1)}),_vm._v(" "),_c('div',{staticClass:"slide"},[_c('nuxt-link',{staticClass:"brand",attrs:{"to":"/contact-lenses"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-center h-100 all-brands"},[_vm._v("\n                        All Brands\n                    ")])])],1)],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }