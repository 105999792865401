<template>
    <!-- Start Main Banner Area -->
    <div class="trustpilot home-trustpilot text-center hidden-mobile">
        <div class="d-flex align-items-center justify-content-center">
            <img :src="formatAssetUrl('images/check-green-thick.png')" class="check-icon" />
            <span id="shipping" class="has-tooltip">Free Shipping & Returns</span>
            <b-tooltip target="shipping" placement="bottom" custom-class="white-tooltip" no-fade>
                Enjoy free shipping on orders over $49, and free 30 or 90 day returns (product pending).
                <nuxt-link to="/return-policy">
                    Learn more
                </nuxt-link>
            </b-tooltip>
        </div>
        <div class="px-3 separator">
            <span></span>
        </div>
        <div class="d-flex align-items-center justify-content-center">
            <img :src="formatAssetUrl('images/check-green-thick.png')" class="check-icon" />
            <span id="service" class="has-tooltip">Legendary Service</span>
            <b-tooltip target="service" placement="bottom" custom-class="white-tooltip" no-fade>
                We’re here to help, Monday through Friday, 9AM to 6PM ET via phone, email, or live chat on
                our <nuxt-link to="/help">contact us page</nuxt-link>.
            </b-tooltip>
        </div>
        <div class="px-3 separator">
            <span></span>
        </div>
        <div class="d-flex align-items-center justify-content-center">
            <img :src="formatAssetUrl('images/check-green-thick.png')" class="check-icon" />
            <span id="insurance" class="has-tooltip">Vision Insurance</span>
            <b-tooltip target="insurance" placement="bottom" custom-class="white-tooltip" no-fade>
                Get reimbursed for your vision care with out-of-network coverage or use your Health Savings Account
                (HSA) or Flexible Savings Account.
                <nuxt-link to="/insurance">
                    Learn more
                </nuxt-link>
            </b-tooltip>
        </div>
        <div class="px-3 separator">
            <span></span>
        </div>
        <div class="d-flex align-items-center justify-content-center">
            <img :src="formatAssetUrl('images/check-green-thick.png')" class="check-icon" alt="award-winning" />
            <span id="guarantee" class="has-tooltip">100% Money Back Guarantee</span>
            <b-tooltip target="guarantee" placement="bottom" custom-class="white-tooltip" no-fade>
                Not happy? We got you. Enjoy 30 or 90 day return policy (product pending), and a variety of warranties.
                <nuxt-link to="/return-policy">
                    Learn more
                </nuxt-link>
            </b-tooltip>
        </div>
        <div class="px-3 separator">
            <span></span>
        </div>
        <CustomTrustpilotWidget />
    </div>
    <!-- End Main Banner Area -->
</template>

<script>
import formatImageMixin from '@/mixins/image-url-formatter';
import TrustpilotStars from '@/components/landing-page/TrustpilotStars';
import CustomTrustpilotWidget from '@/components/landing-page/CustomTrustpilotWidget';

export default {
    mixins: [formatImageMixin],
    name: 'Trustpilot',
    components: {
        TrustpilotStars,
        CustomTrustpilotWidget
    }
};
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/color.scss';
@import '~/assets/scss/variables/breakpoints.scss';
@import '~/assets/scss/variables/font.scss';

img {
    &.check-icon {
        max-width: 16px;
        margin-right: 10px;
    }
}

.home-trustpilot {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 30px;
}

.separator {
    opacity: 0.2;
    span {
        display: block;
        height: 20px;
        width: 1px;
        background-color: #000000;
    }
}

.trustpilot {
    span {
        margin: 0px;
        color: #515e68;
        font-family: $font-primary;
        font-weight: 700;
        font-size: 14px;
    }
}

.trustpilot-widget {
    min-width: 420px;
}

::v-deep {
    .trustpilot-wrapper {
        padding-top: 0px;
    }
}

@media (max-width: 1260px) {
    .trustpilot {
        span {
            font-size: 12px;
        }
    }
}

@media (max-width: $breakpoint1) {
    .trustpilot {
        display: none;
    }
}
</style>
