<template>
    <div class="faq-block inline-links-section">
        <div class="container">
            <h6 class="desktop">
                Frequently Asked Questions
            </h6>
            <h6 class="mobile">
                FAQ
            </h6>
            <FaqWrapper>
                <FaqItem>
                    <template #question>
                        Why buy contact lenses online?
                    </template>
                    <template #answer>
                        We can buy food, pet supplies, clothes, makeup and a car - yep, even a car online. So buying
                        contacts online should be just as safe and easy, right? And that's why we're here.
                    </template>
                </FaqItem>
                <FaqItem>
                    <template #question>
                        Are contacts safe to buy online?
                    </template>
                    <template #answer>
                        We make sure it is. LensDirect is an authorized online retailer by all the major contact lens
                        manufacturers, and back up every sale with epic customer service and an easy-peasy return
                        policy. We think you’re perfect, but just in case you hit the wrong button when entering your
                        prescription, we’ll make sure you get the right ones. And if we make a mistake, well, first
                        we’ll take care of you, and then we’ll figure out how to ensure it never happens again.
                    </template>
                </FaqItem>
                <FaqItem>
                    <template #question>
                        Can I order contact lenses on my own?
                    </template>
                    <template #answer>
                        Yep. Or you can ask your mom or dad, your little brother, roommate, highly trained border
                        collie, a fave barista. All it takes is a valid prescription, and of course - money.
                    </template>
                </FaqItem>
                <FaqItem>
                    <template #question>
                        What do I need to buy contact lenses online?
                    </template>
                    <template #answer>
                        If you have an up-to-date prescription, you're ready to start shopping!
                    </template>
                </FaqItem>
                <FaqItem>
                    <template #question>
                        Can I buy contact lenses online without a prescription?
                    </template>
                    <template #answer>
                        No, but let's turn that "no" into a "yes." If you’ve had a recent eye exam, just let us know who
                        your eye doctor is. We’ll give them a call and get the deets for you. If you haven't had an eye
                        exam in awhile, we still have you covered. <nuxt-link to="/vision-test">Click this link</nuxt-link> for an online vision test. In about
                        10 minutes, your days of not having a prescription will fade into memory.
                    </template>
                </FaqItem>
                <FaqItem>
                    <template #question> Why buy glasses online? </template>
                    <template #answer>
                        Short answer: All the selection, none of the pressure. Slightly longer answer: LensDirect has
                        tons of eyeglass frames, plus contact lenses and accessories. You can scroll through our
                        selection at your own pace, checking out different styles and colors, and even seeing how they
                        look on your face with our virtual try-on technology. When you find the perfect pair, you're
                        only a few clicks away until your new glasses show up at your door. Did we mention free shipping
                        and free returns?
                    </template>
                </FaqItem>
                <FaqItem>
                    <template #question> How do I know what glasses fit my face? </template>
                    <template #answer>
                        By trying them on! Virtually. With our virtual try-on, you can see what any frame looks like on
                        that wonderful face of yours. If you don't know where to start, you can check out this face
                        shape guide to suggest a starting point. We also have a new technology that allows us to measure
                        your face and recommend the frames that make the most sense for you.
                    </template>
                </FaqItem>
            </FaqWrapper>
        </div>
    </div>
</template>

<script>
import FaqWrapper from '@/components/faq/FaqWrapper';
import FaqItem from '@/components/faq/FaqItem';
import formatImageMixin from '@/mixins/image-url-formatter';

export default {
    mixins: [formatImageMixin],
    components: {
        FaqWrapper,
        FaqItem
    }
};
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/font.scss';
@import '~/assets/scss/variables/breakpoints.scss';

.faq-block {
    .mobile {
        display: none;
    }
    .desktop {
        display: block;
    }
    .container {
        padding: 0 7%;
    }
    padding: 60px 0px;
    background-color: #f1f8ff;
    h6 {
        font-weight: 900;
        font-size: 48px;
        font-family: $font-primary;
        line-height: 1.2em;
        text-align: center;
        padding-bottom: 50px;
    }
    .faq-indicator {
        width: 32px;
        height: 32px;
    }
    ::v-deep {
        .faq-item {
            .question {
                font-size: 20px;
                font-weight: bold;
            }
            .icon-wrapper {
                width: 32px;
                height: 32px;
                flex-shrink: 0;
            }
        }
    }
}

@media (max-width: $breakpoint2) {
    .faq-block {
        padding-top: 40px;
        .mobile {
            display: block;
        }
        .desktop {
            display: none;
        }
        .container {
            padding: 0 16px;
        }
        h6 {
            font-size: 32px;
            &.mobile {
                padding-bottom: 10px;
            }
        }
        .faq-indicator {
            width: 24px;
            height: 24px;
        }
        ::v-deep {
            .faq-item {
                .question {
                    font-size: 18px;
                    line-height: 1.2em;
                }
                .icon-wrapper {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
}
</style>
