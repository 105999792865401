<template>
    <div class="renew">
        <div class="container">
            <div class="text-center">
                <h6 class="d-inline-block text-center">
                    <img :src="formatAssetUrl('images/big-dot.svg')" class="big-dot" />
                    "{{ quote }}"
                </h6>
            </div>
            <Banner @change="selectedIndex = $event"></Banner>
        </div>
    </div>
</template>

<script>
import Banner from './Banner';
import formatImageMixin from '@/mixins/image-url-formatter';

export default {
    mixins: [formatImageMixin],
    components: {
        Banner
    },
    data() {
        return {
            selectedIndex: 0
        };
    },
    computed: {
        quote() {
            let mapping = {
                0: 'I Finally Ordered Contacts Online And OMG I’m Never Going Back To A Store',
                1: 'Renew Your Contact Lens Prescription From The Comfort Of Your Own Home',
                2: 'Consistently Selected as a Leader for Customer Service in America',
                3: 'Voted One Of The Best Places To Buy Replacement Prescription Lenses Online',
                4: 'A Convenient, Inexpensive Way To Put New Lenses In Your Favorite Frames'
            };

            return mapping[this.selectedIndex];
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '~/assets/scss/variables/breakpoints.scss';

    .big-dot {
        left: -200px;
        opacity: .3;
    }

    h6 {
        position: relative;
        margin-left: 0px;
        font-weight: normal;
    }

    @media (min-width: $minBreakpoint3) {
        h6 {
            font-size: 32px;
            color: #000000;
        }
    }
</style>
