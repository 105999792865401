<template>
    <client-only>
        <div class="trustpilot-wrapper">
            <div
                id="trustbox-stars"
                class="trustpilot-widget"
                data-locale="en-US"
                data-template-id="5419b6ffb0d04a076446a9af"
                data-businessunit-id="550884280000ff00057e1e9e"
                data-style-height="20px"
                data-style-width="100%"
                data-theme="light"
            >
                <a href="https://www.trustpilot.com/review/lensdirect.com" target="_blank" rel="noopener">
                    Trustpilot</a
                >
            </div>
        </div>
    </client-only>
</template>

<script>
import trustpilotMixin from '@/mixins/trustpilot-mixin';

export default {
    mixins: [trustpilotMixin],
    name: 'TrustpilotStars',
    mounted() {
        this.loadTrustpilotWidget('trustbox-stars');
    }
};
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/breakpoints.scss';

.trustpilot-wrapper {
    display: inline-block;
    vertical-align: top;
    padding-top: 6px;
    width: 420px;
}

@media (max-width: $breakpoint3) {
    .trustpilot-wrapper {
        display: none;
    }
}
</style>
