<template>
    <FeaturedCategoryBlock link="readers" class="readers-homepage-block">
        <h6>Readers</h6>
        <template #link>
            <nuxt-link to="/readers">Shop now</nuxt-link>
        </template>
    </FeaturedCategoryBlock>
</template>

<script>
import FeaturedCategoryBlock from '@/components/landing-page/home/FeaturedCategoryBlock';
export default {
    components: {
        FeaturedCategoryBlock
    }
};
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/base-url.scss';

.readers-homepage-block {
    background-image: url($baseAssetUrl+'images/homepage/readers-tile.png');
    background-size: cover;
    background-position: center top;
}
</style>
