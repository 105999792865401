<template>
    <div class="faq-item">
        <button class="faq-title-button" type="button" @click="onClick">
            <div class="question"><slot name="question"></slot></div>
            <div class="icon-wrapper">
                <slot name="indicator" :open="isOpen">
                    <img
                        v-if="!isOpen"
                        :src="formatAssetUrl('images/plus-dark-gray.svg')"
                        class="faq-indicator"
                        alt="plus icon"
                    />
                    <img
                        v-else
                        :src="formatAssetUrl('images/minus-dark-gray.svg')"
                        class="faq-indicator"
                        alt="minus icon"
                    />
                </slot>
            </div>
        </button>
        <TransitionExpand>
            <div v-if="isOpen">
                <div class="answer">
                    <slot name="answer"></slot>
                </div>
            </div>
        </TransitionExpand>
    </div>
</template>

<script>
import TransitionExpand from '@/components/TransitionExpand';

import formatImageUrlMixin from '@/mixins/image-url-formatter';

export default {
    mixins: [formatImageUrlMixin],
    components: {
        TransitionExpand
    },
    props: {
        closeOthersOnOpen: {
            type: Boolean,
            default: false
        },
        overrideOpen: {
            type: Boolean,
            default: false
        },
        overriddenOpenValue: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        if (this.closeOthersOnOpen) {
            document.body.addEventListener('openedFaqItem', this.closeArea);
        }
    },
    destroyed() {
        if (this.closeOthersOnOpen) {
            document.body.removeEventListener('openedFaqItem', this.closeArea);
        }
    },
    data() {
        return {
            open: false,
            ignoreEvent: false
        };
    },
    computed: {
        isOpen() {
            if (!this.overrideOpen) {
                return this.open;
            }

            return this.overriddenOpenValue;
        }
    },
    methods: {
        closeArea() {
            if (this.ignoreEvent) {
                return;
            }

            this.open = false;
        },
        onClick() {
            this.open = !this.open;
            this.ignoreEvent = true;
            document.body.dispatchEvent(new CustomEvent('openedFaqItem'));
            this.ignoreEvent = false;
            this.$emit('click', this.isOpen);
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '~/assets/scss/variables/color.scss';

    .faq-item {
        display: block;
        padding: 20px 0px;
        border-bottom: dotted $color-grey-border 1px;
        color: $color-grey-text;
        font-size: 16px;
        &:first-child {
            border-top: dotted $color-grey-border 1px;
        }
        .icon-wrapper {
            color: $color-blue;
        }
        button {
            display: flex;
            font-size: 18px;
            color: #000000;
            font-weight: bold;
            justify-content: space-between;
            padding: 0px;
            border: 0px;
            background-color: transparent;
            width: 100%;
            text-align: left;
            &:hover {
                text-decoration: underline;
            }
            .question {
                padding-right: 30px;
            }
        }

        .answer {
            padding-top: 15px;
        }

        ::v-deep {
            a {
                display: inline-block;
            }
        }
        .faq-indicator {
            width: 14px;
        }
    }
</style>
