export default function (elementId, store, router) {
    const listener = (e) => {
        e.preventDefault();

        if (store.state.auth.currentUser?.customer) {
            router.push('/profile/order-history');
            return;
        }

        store.dispatch('setShowReorderLogin', true);
    }

    return {
        addListener() {
            if (document.getElementById(elementId)) {
                document.getElementById(elementId).addEventListener('click', listener);
                return true;
            }

            return false;
        },
        removeListener() {
            if (document.getElementById(elementId)) {
                document.getElementById(elementId).removeEventListener('click', listener);
                return true;
            }

            return false;
        }
    }
}
