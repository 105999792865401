<template>
    <div class="container diy-block">
        <img :src="formatAssetUrl('images/homepage/diy-block-mobile.jpg')" alt="diy lenses" class="mobile-image" />
        <div class="block-text-content">
            <h6>
                DIY Sunglasses Lens Replacement
            </h6>
            <p>
                Replace your sunglasses lenses for <br /><nuxt-link to="/oakley-lens-replacement">Oakley</nuxt-link>,
                <nuxt-link to="/ray-ban-lens-replacement">Ray-Ban</nuxt-link>,
                <nuxt-link to="/mauijim-lens-replacement">Maui Jim</nuxt-link> from home
            </p>
            <div class="button-wrapper">
                <nuxt-link to="/diy-lens-replacement" class="btn-black link hover-blue">Browse all</nuxt-link>
            </div>
        </div>
    </div>
</template>

<script>
import formatImageMixin from '@/mixins/image-url-formatter';
export default {
    mixins: [formatImageMixin]
};
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/font.scss';
@import '~/assets/scss/variables/color.scss';
@import '~/assets/scss/variables/breakpoints.scss';
@import '~/assets/scss/components/landing-page/home/product-blocks.scss';
@import '~/assets/scss/variables/base-url.scss';

.container {
    background-image: url($baseAssetUrl+'images/homepage/diy-block-desktop.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: -10px center;
    p {
        a {
            color: $color-black;
            text-decoration: underline;
            font-weight: bold;
            display: inline;
            &:hover {
                color: #343434;
                text-decoration: none;
            }
        }
    }
    .block-text-content {
        margin-left: auto;
        h6 {
            width: 100%;
            max-width: none;
        }
    }
    .button-wrapper {
        .btn-brand {
            background-color: #f0f5f8;
            padding: 15px 10px;
            text-align: center;
            border: solid #c2c2c2 1px;
            border-radius: 6px;
            min-width: 145px;
            img {
                max-height: 32px;
                &.maui-jim {
                    max-height: 40px;
                }
            }
            &:hover {
                border-color: #000000;
            }
        }
    }

    .browse-all {
        color: #000000;
        text-decoration: underline;
        &:hover {
            color: $color-blue;
        }
    }
}

@media (max-width: $breakpoint1) {
    .container {
        background-position: -180px center;
        .block-text-content {
            width: 60%;
        }
    }
}

@media (max-width: $breakpoint4) {
    .container {
        .block-text-content {
            margin-left: auto;
            margin-right: auto;
            width: 90%;
        }

        .button-wrapper {
            .btn-brand {
                background-color: #ffffff;
                min-width: 98px;
                img {
                    max-height: 28px;
                    &.maui-jim {
                        max-height: 32px;
                    }
                }
            }
        }
    }
}
</style>
