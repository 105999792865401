<template>
    <!-- Start Testimonials Area -->
    <section class="testimonials-area" data-jarallax='{"speed": 0.3}'>
        <h3>We don't like to talk about ourselves</h3>
        <p class="mt-3">But we don't mind if our customers do</p>
        <div class="testimonials-slides owl-carousel owl-theme">
            <ssr-carousel
                :loop="true"
                :peek-right="40"
                :slides-per-page="3"
                :responsive="[
                    {
                        maxWidth: 1025,
                        slidesPerPage: 1,
                        peekRight: 40
                    }
                ]"
            >
                <div class="slide" v-for="(item, index) in items" :key="index + 1">
                    <div class="single-testimonials">
                        <img :src="formatAssetUrl('images/stars.svg')" />
                        <p>{{ item.feedback }}</p>

                        <div class="client-info">
                            <h6>{{ item.name }}</h6>
                            <div class="verified">
                                <img :src="formatAssetUrl('images/verify.svg')" /> Verified customer
                            </div>
                        </div>
                    </div>
                </div>
            </ssr-carousel>
        </div>
    </section>
    <!-- End Testimonials Area -->
</template>

<script>
import formatImageMixin from '@/mixins/image-url-formatter';

export default {
    mixins: [formatImageMixin],
    data() {
        return {
            items: [
                {
                    id: 1,
                    name: 'Christopher R.',
                    feedback:
                        "My online vision test was simple and easy to follow. Best of all, it was free! I'll be a repeat customer for years to come."
                },
                {
                    id: 2,
                    name: 'Krista C.',
                    feedback:
                        "I've had the same prescription for the same 20 years, and my eye doctor couldn't get me in until next month. Lens Direct approved my prescription within an hour - amazing service and now I have more contacts on the way!"
                },
                {
                    id: 3,
                    name: 'Andrew S.',
                    feedback:
                        "The kind of service that deserves 5 stars. When there was an issue with my order, someone reached out to me and immediately got things sorted. No waiting around for a response or back and forth, just \"Let's solve this\" and a solution."
                },
                {
                    id: 4,
                    name: 'Brittiney K.',
                    feedback:
                        "GREAT company is an understatement, each part of my experience was a breeze. I was initially skeptical about sending my glasses off, but after speaking to customer service I was confident that Lens Direct genuinely values their customers and is passionate about making sure you have a seamless experience. I cannot speak highly enough of this company."
                },
                {
                    id: 5,
                    name: 'Jose M.',
                    feedback:
                        "Impressed with the technology used on my home laptop to scan and measure pupil distance. I'm very pleased with my Lens Replacement, great service and prompt turnaround. I will be purchasing another pair of glasses with Lens Direct."
                },
                {
                    id: 6,
                    name: 'Natalie W.',
                    feedback:
                        "Lens Direct's excellent service and quality is always consistent. I am a recurring customer and have never had a bad experience. I recommend Lens Direct ANY time folks talk about wearing contacts."
                }
            ]
        };
    }
};
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/breakpoints.scss';

.testimonials-slides {
    &::v-deep {
        .ssr-carousel-slides {
            .ssr-carousel-mask {
                /* hack to let the vertical shadow show on the slide */
                margin-bottom: -50px;
                padding-bottom: 50px;
                padding-top: 50px;
                margin-top: -50px;
            }
        }
    }
}

@media (max-width: $breakpoint2) {
    .testimonials-area {
        margin-top: 30px;
        .testimonials-slides {
            margin-top: 0px;
        }
    }
}
</style>
