<template>
    <div class="container featured-categories">
        <HollyBlock />
        <HyannisBlock />
        <ReadersBlock />
        <KidsBlock />
    </div>
</template>

<script>
import HollyBlock from '@/components/landing-page/home/HollyBlock';
import HyannisBlock from '@/components/landing-page/home/HyannisBlock';
import KidsBlock from '@/components/landing-page/home/KidsBlock';
import ReadersBlock from '@/components/landing-page/home/ReadersBlock';

export default {
    components: {
        HollyBlock,
        HyannisBlock,
        ReadersBlock,
        KidsBlock
    }
};
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/breakpoints.scss';

.container {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    padding: 0px;
}

@media (max-width: $breakpoint2) {
    .container {
        padding: 20px;
        gap: 20px;
    }
}
</style>
