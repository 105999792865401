<template>
    <div class="hidden-desktop mobile-product-type-bubbles-component">
        <ssr-carousel
            :slides-per-page="null"
            :peek-right="40"
            :gutter="10"
        >
            <div class="slide" v-for="(productType, index) in productTypes" :key="index">
                <nuxt-link :to="productType.path">
                    {{ productType.name }}
                </nuxt-link>
            </div>
        </ssr-carousel>
    </div>
</template>

<script>
export default {
    data() {
        return {
            productTypes: [
                {
                    name: 'Contact Lenses',
                    path: '/contact-lenses'
                },
                {
                    name: 'Glasses',
                    path: '/glasses'
                },
                {
                    name: 'Sunglasses',
                    path: '/sunglasses'
                },
                {
                    name: 'Lens Replacement',
                    path: '/lens-replacement'
                },
                {
                    name: 'Accessories',
                    path: '/accessories'
                },
            ]
        };
    }
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/color.scss';

.mobile-product-type-bubbles-component {
    padding: 15px 0px;
    padding-left: 15px;
    padding-bottom: 0px;
    .slide {
        a {
            display: inline-block;
            white-space: nowrap;
            border: solid $color-grey-border 1px;
            padding: 5px 15px;
            border-radius: 100px;
            color: #000000;
            font-size: 12px;
        }
        &:last-child {
            padding-right: 15px;
        }
    }
}
</style>
