<template>
    <div class="container bogo-homepage-block">
        <img :src="formatAssetUrl('images/homepage/bogo-slider-202404-sunglasses-compressed.jpg')" alt="glasses" class="mobile-image" />
        <div class="block-text-content">
            <h6>
                Buy one, get one free
            </h6>
            <p>
                Double the frames, half the cost - limited time offer.
            </p>
            <div class="button-wrapper">
                <nuxt-link to="/bogo" class="btn btn-outline outline-gray outline-thin link">
                    Browse all
                </nuxt-link>
                <nuxt-link to="/bogo" class="btn btn-primary shop-now">
                    Shop now
                </nuxt-link>
            </div>
        </div>
        <div class="frame-text">
            <a href="/forge-tortoise-and-gold">Forge in Tortoise</a> - <a href="/forge-black-gunmetal">Forge in Black</a>
        </div>
    </div>
</template>

<script>
import formatImageMixin from '@/mixins/image-url-formatter';

export default {
    mixins: [formatImageMixin]
};
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/font.scss';
@import '~/assets/scss/variables/breakpoints.scss';
@import '~/assets/scss/components/landing-page/home/product-blocks.scss';
@import '~/assets/scss/variables/base-url.scss';

.container {
    position: relative;
    background-color: #dedede;
    background-image: url($baseAssetUrl+'images/homepage/bogo-hp-tile-202406-compressed.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right center;
    .block-text-content {
        width: 40%;
        margin-left: auto;
        h6 {
            width: 70%;
            line-height: 1em;
            margin-bottom: 20px;
        }
        .button-wrapper {
            .shop-now {
                display: none;
            }
        }
    }
    .frame-text {
        position: absolute;
        bottom: 15px;
        left: 20px;
        a {
            display: inline;
            color: #000000;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
.link {
    padding: 16px 0;
}
@media (max-width: $breakpoint4) {
    .container {
        .block-text-content {
            width: 100%;
            h6 {
                width: 100%;
            }
            .button-wrapper {
                .shop-now {
                    display: block;
                    font-weight: bold;
                }
                .btn-outline {
                    display: none;
                }
            }
        }

        .frame-text {
            bottom: auto;
            right: 10px;
            left: auto;
            top: calc(50% - 50px);
        }
    }
}
</style>
