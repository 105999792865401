<template>
    <div class="renew">
        <div class="container">
            <div class="online-eye-exam">
                <div class="row">
                    <div class="col-md-6 col-sm-12 text-center renew-order">
                        <h3 class="mb-3">Online Vision Test</h3>
                        <p>Renew your glasses or contacts prescription in just 5 minutes from anywhere</p>
                        <a href="/vision-test"><button class="btn btn-primary">Renew my prescription</button></a>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <img
                            :src="formatAssetUrl('images/homepage/mobile-online.jpg')"
                            alt="online-eye-exam"
                            class="mobile-renew-img"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import formatImageMixin from '@/mixins/image-url-formatter';
export default {
    mixins: [formatImageMixin]
};
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/breakpoints.scss';

.renew {
    margin-top: 0px;
    margin-bottom: 48px;
    .online-eye-exam {
        .btn {
            font-weight: bold;
        }
    }
}

@media (max-width: $breakpoint2) {
    .renew .online-eye-exam .btn {
        font-weight: bold;
    }

    .renew {
        margin-bottom: 20px;
    }
}
</style>
