<template>
    <div class="custom-video-component" v-if="video" :class="componentStyle">
        <div class="video-overlay" v-if="!showVideo"></div>
        <button
            type="button"
            class="video-control-wrapper"
            @click.stop="onClickPlay"
            @mouseover="onMouseOver"
            @mouseout="onMouseOut"
        >
            <div class="video-control-icon">
                <slot name="play-button-icon">
                    <i class="fas fa-play" aria-label="Play video"></i>
                </slot>
            </div>
            <div class="video-control-text">
                Play video
            </div>
        </button>
        <img
            v-if="previewImage"
            :src="previewImage.url"
            :alt="previewImage.alt ? previewImage.alt : 'Video preview'"
            class="video-preview-image"
            @click.stop="onClickPlay"
        />
        <video
            controls
            webkit-playsinline
            playsinline
            ref="video"
            @ended="onVideoEnded"
            @pause="onVideoPaused"
            @playing="onVideoPlaying"
        >
            <source :src="video.url" :type="video.type" />
        </video>
    </div>
</template>

<script>
export default {
    props: {
        video: {
            type: Object
        },
        previewImage: {
            type: Object
        },
        isPaused: {
            type: Boolean,
            default: null
        }
    },
    data() {
        return {
            isLoading: true,
            showVideo: false,
            isPlaying: false
        };
    },
    mounted() {
        this.$refs.video.addEventListener('canplaythrough', () => {
            this.isLoading = false;
        });

        // Fallback, set to false after 2 seconds
        window.setTimeout(() => {
            this.isLoading = false;
        }, 2000);
    },
    computed: {
        componentStyle() {
            if (this.isLoading) {
                return { 'is-loading': true };
            }

            if (this.showVideo) {
                return { 'show-video': true };
            }

            return {};
        }
    },
    watch: {
        isPaused(isPaused) {
            if (isPaused) {
                this.$refs.video.pause();
            }
        }
    },
    methods: {
        onVideoPlaying() {
            this.$emit('isPlaying');
        },
        onVideoPaused() {
            this.$emit('isPaused');
        },
        onMouseOver(e) {
            this.$emit('mouseover', e);
        },
        onMouseOut(e) {
            this.$emit('mouseout', e);
        },
        onClickPlay(e) {
            e.preventDefault();

            this.showVideo = true;

            this.$refs.video.play();

            this.$emit('play');
        },
        onVideoEnded() {
            this.showVideo = false;
            this.$emit('ended');
        }
    }
};
</script>

<style lang="scss" scoped>
.custom-video-component {
    position: relative;
    width: 100%;
    max-width: 814px;
    align-self: flex-start;
    .video-overlay {
        position: absolute;
        background-color: #000000;
        opacity: 0.4;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }
    .video-control-wrapper {
        position: absolute;
        background-color: transparent;
        border: 0px;
        padding: 0px;
        margin: 0px;
        cursor: pointer;
        left: 50%;
        margin-left: -35px;
        top: 50%;
        margin-top: -35px;
        z-index: 100;
        .video-control-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 70px;
            height: 70px;
            background-color: #239eed;
            border-radius: 100px;
            color: #ffffff;
            font-size: 20px;
        }
        &:hover {
            .video-control-icon {
                background-color: #007fd1;
            }
        }
        .video-control-text {
            text-transform: uppercase;
            font-size: 0.75em;
            width: 70px;
            color: #ffffff;
            font-weight: 600;
            text-align: center;
            padding-top: 8px;
        }
    }
    img {
        max-width: 100%;
    }
    video {
        position: absolute;
        left: 0px;
        top: 0px;
        opacity: 0;
        width: 100%;
        max-height: 460px;
        pointer-events: none;
    }
    &.show-video {
        video {
            position: relative;
            opacity: 1;
            pointer-events: auto;
        }
        .video-preview-image {
            display: none;
        }
        .video-overlay {
            opacity: 0;
        }
    }
    &.is-loading,
    &.show-video {
        .video-control-wrapper {
            display: none;
        }
    }
    &.white-button-theme {
        .video-overlay {
            transition: opacity 0.2s;
            filter: blur(55px);
        }
        &.is-hovering-play-button {
            .video-overlay {
                opacity: 0.1;
            }
        }
        &:hover {
            cursor: pointer;
            .video-control-icon {
                background-color: transparent;
                border: solid rgba(255, 255, 255, 1) 4px !important;
            }

            .video-overlay {
                opacity: 0.1;
            }
        }
        .video-control-wrapper {
            margin-left: -55px;
            margin-top: -55px;
            .video-control-icon {
                background-color: transparent;
                border: solid rgba(255, 255, 255, 0.4) 4px;
                transition: border 0.2s;
                font-size: 18px;
                width: 110px;
                height: 110px;
                &:hover {
                    background-color: transparent;
                    border: solid rgba(255, 255, 255, 1) 4px;
                }
            }
            &:hover {
                .video-control-icon {
                    background-color: transparent;
                }
            }
            img {
                &.play-icon {
                    width: 45px;
                    height: 45px;
                    margin-left: 8px;
                }
            }
            .video-control-text {
                display: none;
            }
        }
        &.blurred-button-theme {
            .video-overlay {
                opacity: .2;
                transition: opacity .2s;
                filter: blur(10px);
            }
            &:hover {
                .video-overlay {
                    opacity: .05;
                }
            }
            .video-control-wrapper {
                margin-left: -64px;
                margin-top: -64px;
                .video-control-icon {
                    width: 128px;
                    height: 128px;
                    font-size: 32px;
                    border: 0px !important;
                    backdrop-filter: blur(1px);
                    background-color: rgba(255,255,255,.1);
                    img {
                        width: 30px;
                        height: 33px;
                    }
                }
            }
            &:hover {
                .video-control-wrapper {
                    .video-control-icon {
                        background-color: rgba(255,255,255,.2);
                    }
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .custom-video-component {
        width: 100%;
        &.white-button-theme {
            &.blurred-button-theme {
                .video-control-wrapper {
                    margin-left: -30px;
                    margin-top: -30px;
                    .video-control-icon {
                        width: 60px;
                        height: 60px;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
</style>
