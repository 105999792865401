<template>
    <div class="renew">
        <div class="container">
            <div class="auto-refill">
                <div class="row">
                    <div class="col-md-6 col-sm-12 ">

                    </div>
                    <div class="col-md-6 col-sm-12 text-center renew-order">
                        <img :src="formatAssetUrl('images/autorefill/hp-tile-subscribe-icon.webp')" alt="shipping box surrounded by circular arrows" class="subscribe-icon">
                        <h3 class="mb-3">Subscribe and save</h3>
                        <p>Save 20% on your first order and 10% always (and stop running out of contacts)</p>
                        <nuxt-link to="/subscribe-and-save"><button class="btn btn-primary">Learn more</button></nuxt-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import formatImageMixin from '@/mixins/image-url-formatter';

export default {
    mixins: [formatImageMixin]
};
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/breakpoints.scss';

.big-dot {
    opacity: 0.3;
}

.renew {
    margin-top: 0px;
    margin-bottom: 48px;
    .auto-refill {
        .btn {
            font-weight: bold;
        }
    }
}

@media (min-width: $minBreakpoint3) {
    h6 {
        font-size: 32px;
        color: #000000;
        font-weight: normal;
    }
}

@media (max-width: $breakpoint2) {
    .renew .online-eye-exam .btn {
        font-weight: bold;
    }

    .renew {
        margin-bottom: 20px;
        .auto-refill {
            margin-top: 10px;
        }
    }
}
</style>
