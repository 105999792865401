<template>
    <div class="featured-category-block">
        <nuxt-link :to="link"></nuxt-link>
        <div class="block-text-content">
            <slot></slot>
            <div class="learn-more-text">
                <slot name="link"></slot> <font-awesome-icon class="icon" icon="fa-solid fa-chevron-right" />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        link: {
            type: String,
            required: true
        }
    },
    methods: {
        goToLink() {
            this.$router.push({ path: this.link });
        }
    }
};
</script>
<style lang="scss" scoped>
@import '~/assets/scss/variables/font.scss';
@import '~/assets/scss/variables/color.scss';
@import '~/assets/scss/variables/breakpoints.scss';

.featured-category-block {
    position: relative;
    display: flex;
    width: calc(50% - 20px);
    min-height: 338px;
    padding: 20px;
    border-radius: 6px;
    &::before {
        position: absolute;
        content: '';
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        border-radius: 6px;
        background: transparent linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .2) 100%) 0% 0% no-repeat
            padding-box;
    }
    &:hover {
        &::before {
            background: transparent linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%) 0% 0% no-repeat
                padding-box;
        }
        .block-text-content {
            .learn-more-text {
                a {
                    color: rgba(255, 255, 255, 1);
                }
            }
        }
    }
    h6 {
        color: #ffffff;
        font-size: 32px;
        font-weight: 900;
        font-family: $font-primary;
        line-height: 1.2em;
    }

    > a {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        z-index: 15;
    }
    .block-text-content {
        z-index: 10;
        margin-top: auto;
        width: 50%;
        .learn-more-text {
            font-size: 14px;
            color: rgba(255, 255, 255, 0.8);
            font-weight: 600;
            a {
                z-index: 20;
                display: inline-block;
                color: rgba(255, 255, 255, 0.8);
                &:hover {
                    text-decoration: underline;
                }
            }
            .icon {
                margin-left: 5px;
                font-size: 12px;
            }
        }
    }
}

@media (max-width: $breakpoint4) {
    .featured-category-block {
        width: 100%;
        gap: 20px;
        min-height: 195px;
        h6 {
            font-size: 24px;
            font-family: $font-primary;
        }
        .block-text-content {
            width: 75%;
        }
    }
}
</style>
