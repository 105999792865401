<template>
    <div class="hero-slider-component">
        <ssr-carousel
            loop
            peek-right="40"
            peek-left="40"
            show-arrows
            center
            paginate-by-slide
            :slides-per-page="3"
            :responsive="[
                {
                    maxWidth: 1388,
                    slidesPerPage: 2,
                    peekLeft: 100,
                    peekRight: 100
                },
                {
                    maxWidth: 1060,
                    slidesPerPage: 1,
                    peekLeft: 10,
                    peekRight: 40,
                    showArrows: false
                }
            ]"
        >
            <template #back-arrow>
                <img :src="formatAssetUrl('images/angle-left-black.png')" alt="angle left icon">
            </template>
            <template #next-arrow>
                <img :src="formatAssetUrl('images/angle-right-black.png')" alt="angle right icon">
            </template>

            <div class="slide light" :style="{ 'background-image': 'url(\'' + formatAssetUrl('images/homepage/contacts-tile-v2-resized-compressed.png') + '\')' }" @click="onClickSlide('/contact-lenses')">
                <div class="slide-title">
                    Every brand of contacts
                </div>
                <div class="slide-text">
                    Best prices.  Earn rewards with fast, free shipping.
                </div>
                <div class="slide-links dark">
                    <nuxt-link to="/contact-lenses" class="hover-underline"><strong>Shop contacts</strong> <img :src="formatAssetUrl('images/arrow-right-short-white.png')" alt="arrow"></nuxt-link>
                    <a href="/profile/order-history" id="LDReorderLensesDesktopHomeHero" @click.stop>Quick reorder</a>
                </div>
            </div>

            <div class="slide" :class="{ 'not-first-mobile': !isMounted }" :style="{ 'background-image': 'url(\'' + formatAssetUrl('images/homepage/diy-tile-20240819-compressed.png') + '\')' }" @click="onClickSlide('/diy-lens-replacement/choose-your-brand')">
                <div class="slide-title">
                    <img :src="formatAssetUrl('images/volt-pro-logo-by-lensdirect.svg')" alt="volt pro by lensdirect" />
                </div>
                <div class="slide-text">
                    The best HD color enhancing lenses for your favorite activity
                </div>
                <div class="slide-links dark">
                    <nuxt-link to="/diy-lens-replacement/choose-your-brand" class="hover-underline"><strong>Shop VoltPro</strong> <img :src="formatAssetUrl('images/arrow-right-short-yellow.svg')" alt="arrow"></nuxt-link>
                </div>
            </div>

            <div class="slide" :class="{ 'not-first-mobile': !isMounted }" :style="{ 'background-image': 'url(\'' + formatAssetUrl('images/homepage/rewards-tile-blue.png') + '\')' }" @click="onClickSlide('/rewards', true)">
                <div class="slide-title larger-width">
                    Join the club
                </div>
                <div class="slide-text">
                    Earn points & rewards every time you shop.
                </div>
                <div class="slide-links">
                    <a href="/rewards" class="hover-underline"><strong>Learn more</strong> <img :src="formatAssetUrl('images/arrow-right-short-white.png')" alt="arrow"></a>
                </div>
            </div>

            <div class="slide light lens-replacement-slide" :class="{ 'not-first-mobile': !isMounted }" :style="{ 'background-image': 'url(\'' + formatAssetUrl('images/homepage/lens-replacement-hero-slider-202407-compressed.jpg') + '\')' }" @click="onClickSlide('/lens-replacement/prescription-lens-replacement')">
                <div class="slide-title larger-width">
                    New lenses for your frames
                </div>
                <div class="slide-text">
                    Get new lenses at a fraction of what it would cost at the store.
                </div>
                <div class="slide-links">
                    <nuxt-link to="/lens-replacement/prescription-lens-replacement" class="hover-underline"><strong>Shop now</strong> <img :src="formatAssetUrl('images/arrow-right-short-black.png')" alt="arrow"></nuxt-link>
                </div>
            </div>

        </ssr-carousel>
    </div>
</template>

<script>
import formatImageMixin from '@/mixins/image-url-formatter';
import reorderLensesEvent from '@/utils/reorder-lenses-event';
import saleActiveMixin from '@/mixins/sale-active-mixin';

import Modal from '@/components/modals/Modal';

export default {
    components: {
        Modal
    },
    mixins: [formatImageMixin, saleActiveMixin],
    mounted() {
        reorderLensesEvent('LDReorderLensesDesktopHomeHero', this.$store, this.$router).addListener();
        this.isMounted = true;
    },
    destroyed() {
        reorderLensesEvent('LDReorderLensesDesktopHomeHero', this.$store, this.$router).removeListener();
    },
    data() {
        return {
            isMounted: false,
            isModalOpen: false
        };
    },
    computed: {
        user() {
            return this.$store.state.auth.currentUser?.customer;
        },
        isSaleActive() {
            return this.getIsSaleActive(
                new Date('2024-05-10T00:00:00-04:00'),
                new Date('2024-05-18T02:59:59-04:00')
            );
        }
    },
    methods: {
        onClickSlide(path, triggerReload = false) {
            if (!triggerReload) {
                this.$router.push(path);
                return;
            }

            window.location = path;
        },
        onClickOpenGlobalBanner() {
            this.$store.dispatch('setGlobalBannerModalOpen', true);
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/breakpoints.scss';

.hero-slider-component {
    padding: 20px 0px;
    .slide {
        position: relative;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        max-width: 600px;
        height: 400px;
        padding: 30px;
        border-radius: 10px;
        background-size: cover;
        background-position: center;
        color: #ffffff;
        &.light {
            color: #000000;
            .slide-links {
                a {
                    color: #000000;
                }
                &.dark {
                    a {
                        color: #ffffff;
                    }
                }
            }
        }
        .slide-title {
            font-size: 40px;
            font-weight: bold;
            width: 51.25%;
            line-height: 40px;
            &.larger-width {
                width: 54%;
            }
            &.slide-title-bigger {
                font-size: 58px;
                line-height: 1.1em;
            }
        }
        .lower-right-text {
            position: absolute;
            bottom: 10px;
            right: 20px;
            font-size: 14px;
            a {
                display: inline;
                color: #000000;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        .slide-text {
            padding-top: 30px;
            font-size: 24px;
            line-height: 30px;
            width: 40%;
            &.smaller-width {
                width: 33%;
            }
        }
        .slide-links {
            display: flex;
            gap: 30px;
            margin-top: auto;
            font-size: 20px;
            a {
                color: #ffffff;
                display: inline;
                &:hover {
                    text-decoration: underline;
                }
            }
            img {
                width: 18px;
                margin-left: 5px;
            }
        }
        &.has-coupon-code {
            .slide-links {
                flex-direction: column;
                gap: 0px;
            }
        }
        &:hover {
            .hover-underline {
                text-decoration: underline;
            }
        }
        .slide-asterik-section {
            color: #970000;
            font-size: 13px;
            padding-top: 5px;
            font-weight: 600;
        }
        .slide-percent {
            position: absolute;
            top: 20px;
            right: 20px;
        }
        &.lens-replacement-slide {
            background-repeat: no-repeat;
            background-position-x: 48px;
            background-color: #f2f3f5;
        }
    }
    ::v-deep {
        .ssr-carousel-arrows {
            button {
                z-index: 10;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40px;
                height: 40px;
                border-radius: 100px;
                box-shadow: 0px 2px 3px 0px rgba(0,0,0,.2);
                background-color: #ffffff;
                img {
                    width: 13px;
                }
            }
            .ssr-carousel-back-button {
                left: 1%;
                img {
                    margin-right: 3px;
                }
            }
            .ssr-carousel-next-button {
                right: 1%;
                img {
                    margin-left: 3px;
                }
            }
            &::before {
                position: absolute;
                content: "";
                left: 0px;
                top: 0px;
                bottom: 0px;
                width: 80px;
                background-color: rgba(255,255,255,.3);
            }
            &::after {
                position: absolute;
                content: "";
                right: 0px;
                top: 0px;
                bottom: 0px;
                width: 80px;
                background-color: rgba(255,255,255,.3);
            }
        }
    }
}

@media (max-width: 1599px) {
    .hero-slider-component {
        .slide {
            height: 311px;
            .slide-title {
                font-size: 34px;
                line-height: 36px;
                &.larger-width {
                    width: 60%;
                }
            }
            .slide-text {
                font-size: 18px;
                line-height: 24px;
            }
            .slide-links {
                font-size: 16px;
            }
        }
    }
}

@media (max-width: $desktopBreakpoint2) {
    .hero-slider-component {
        .slide {

            .slide-title {
                width: 55%;
                &.larger-width {
                    width: 65%;
                }
            }
        }
        ::v-deep {
            .ssr-carousel-arrows {
                &::before {
                    width: 70px;
                }
                &::after {
                    width: 70px;
                }
            }
        }
    }
}

@media (max-width: 1388px) {
    .hero-slider-component {
        .slide {
            max-width: 640px;
            height: 376px;
            .slide-title {
                font-size: 32px;
                line-height: 32px;
            }
            .slide-text {
                font-size: 21px;
            }
            &.lens-replacement-slide {
                background-position-x: 78px;
            }
        }
    }
}

@media (max-width: $desktopBreakpoint4) {
    .hero-slider-component {
        .slide {
            height: 320px;
            .slide-title {
                font-size: 24px;
                line-height: 28px;
                &.slide-title-bigger {
                    font-size: 32px;
                }
            }
            .slide-text {
                font-size: 16px;
                line-height: 18px;
            }
            .slide-links {
                font-size: 14px;
                img {
                    width: 14px;
                }
            }
        }
    }
}

@media (max-width: $breakpoint2) {
    .hero-slider-component {
        .slide {
            height: 260px;
            padding: 15px;
            &.not-first-mobile {
                display: none;
            }
            &:hover {
                .hover-underline {
                    text-decoration: none;
                }
            }
            &.new-frames-banner {
                .slide-text {
                    width: 45%;
                    font-size: 15px;
                }
            }
            .slide-percent {
                img {
                    width: 115px;
                }
            }
            &.lens-replacement-slide {
                background-position-x: 10px;
                .slide-title {
                    &.larger-width {
                        width: 54%;
                    }
                }
            }
        }
        ::v-deep {
            .ssr-carousel-arrows {
                display: none !important;
            }
        }
    }
}

@media (max-width: $breakpoint7) {
    .hero-slider-component {
        .slide {
            &.lens-replacement-slide {
                .slide-title {
                    &.larger-width {
                        width: 60%;
                    }
                }
                .slide-text {
                    width: 45%;
                }
            }
        }
    }
}
</style>
