<template>
    <div class="about-us-block">
        <div class="container">
            <div class="about-us-heading">
                <h5>
                    We're a Family-Owned Business
                </h5>
                <p>
                    Don’t worry, you can skip our Thanksgiving Dinner
                </p>
            </div>
            <div class="video-stats-wrapper">
                <div class="stats-wrapper">
                    <ul>
                        <li>
                            <h6>30+</h6>
                            Years in Business
                        </li>
                        <li>
                            <h6>
                                5000+
                            </h6>
                            Five Star Reviews
                        </li>
                        <li>
                            <h6>1m+</h6>
                            Happy Customers
                        </li>
                    </ul>
                </div>
                <div class="company-video-wrapper">
                    <CustomVideo
                        :video="video"
                        :previewImage="previewImage"
                        @mouseover="onVideoMouseOver"
                        @mouseout="onVideoMouseOut"
                        :class="{ 'is-hovering-play-button': isHoveringPlayButton }"
                    >
                        <template #play-button-icon>
                            <img :src="formatAssetUrl('images/triangle.svg')" alt="play video icon" class="play-icon" />
                        </template>
                    </CustomVideo>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CustomVideo from '@/components/CustomVideo';
import formatImageMixin from '@/mixins/image-url-formatter';

export default {
    mixins: [formatImageMixin],
    components: {
        CustomVideo
    },
    data() {
        return {
            isHoveringPlayButton: false
        };
    },
    computed: {
        video() {
            return {
                url: 'https://assets.lensdirect.com/assets/2.0/video/lensdirect-aboutus-compressed.mp4',
                type: 'video/mp4'
            };
        },
        previewImage() {
            return {
                url: this.formatAssetUrl('images/homepage/video-thumb-2.jpg'),
                alt: 'We are LensDirect'
            };
        }
    },
    methods: {
        onVideoMouseOver() {
            this.isHoveringPlayButton = true;
        },
        onVideoMouseOut() {
            this.isHoveringPlayButton = false;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/font.scss';
@import '~/assets/scss/variables/color.scss';
@import '~/assets/scss/variables/breakpoints.scss';
@import '~/assets/scss/variables/base-url.scss';

.about-us-block {
    position: relative;
    background-image: url($baseAssetUrl+'images/homepage/video-block-bg-dark-2.jpg');
    background-size: 120%;
    background-repeat: no-repeat;
    background-position: center;
    padding: 60px 0px;
    ::v-deep {
        .custom-video-component {
            .video-overlay {
                transition: opacity 0.2s;
                filter: blur(55px);
            }
            &.is-hovering-play-button {
                .video-overlay {
                    opacity: 0.1;
                }
            }
            &:hover {
                cursor: pointer;
                .video-control-icon {
                    background-color: transparent;
                    border: solid rgba(255, 255, 255, 1) 4px !important;
                }

                .video-overlay {
                    opacity: 0.1;
                }
            }
            .video-control-wrapper {
                margin-left: -55px;
                margin-top: -55px;
                .video-control-icon {
                    background-color: transparent;
                    border: solid rgba(255, 255, 255, 0.4) 4px;
                    transition: border 0.2s;
                    font-size: 18px;
                    width: 110px;
                    height: 110px;
                    &:hover {
                        background-color: transparent;
                        border: solid rgba(255, 255, 255, 1) 4px;
                    }
                }
                &:hover {
                    .video-control-icon {
                        background-color: transparent;
                    }
                }
                img {
                    &.play-icon {
                        width: 45px;
                        height: 45px;
                        margin-left: 8px;
                    }
                }
                .video-control-text {
                    display: none;
                }
            }
        }
    }
    .container {
        text-align: center;
        p {
            color: rgba(255, 255, 255, 0.4);
            font-size: 18px;
        }
        h5 {
            font-weight: 900;
            font-family: $font-primary;
            color: #ffffff;
            font-size: 48px;
            line-height: 1.2em;
        }
        .video-stats-wrapper {
            display: flex;
            justify-content: center;
            gap: 30px;
            padding-top: 30px;
            .stats-wrapper {
                ul {
                    display: flex;
                    flex-direction: column;
                    list-style-type: none;
                    margin: 0px;
                    padding: 0px;
                    gap: 30px;
                    height: 100%;
                    li {
                        display: flex;
                        flex-direction: column;
                        flex: 1;
                        align-items: center;
                        justify-content: center;
                        color: rgba(255, 255, 255, 0.4);
                        background-color: rgba(255, 255, 255, 0.05);
                        margin: 0px;
                        padding: 0px;
                        list-style-type: none;
                        width: 264px;
                        border-radius: 12px;
                        font-weight: bold;
                        h6 {
                            line-height: 1em;
                            color: #ffffff;
                            font-size: 56px;
                            font-weight: 900;
                            font-family: $font-primary;
                            margin-bottom: 0px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $breakpoint1) {
    .about-us-block {
        .container {
            .video-stats-wrapper {
                .stats-wrapper {
                    ul {
                        li {
                            width: 200px;
                            h6 {
                                font-size: 42px;
                                margin-bottom: 5px;
                                line-height: 1em;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $breakpoint2) {
    .about-us-block {
        .container {
            .video-stats-wrapper {
                .stats-wrapper {
                    ul {
                        li {
                            width: 150px;
                            font-size: 14px;
                            font-weight: 600;
                            h6 {
                                font-size: 24px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $breakpoint2) {
    .about-us-block {
        padding: 0px;
        background-position: center -100px;
        background-image: url($baseAssetUrl+'images/homepage/video-block-bg-mobile.jpg');
        .container {
            padding: 0px;
            .about-us-heading {
                display: none;
            }
            .video-stats-wrapper {
                flex-direction: column;
                gap: 0px;
                padding-top: 0px;
                .stats-wrapper {
                    ul {
                        width: 100%;
                        align-items: center;
                        justify-content: flex-start;
                        padding: 30px 0px;
                        flex-direction: row;
                        background-color: rgba(255, 255, 255, 0.1);
                        gap: 0px;
                        li {
                            position: relative;
                            background-color: transparent;
                            flex: 1;
                            width: auto;
                            padding: 0px 15px;
                            line-height: 1.2em;
                            &::after {
                                position: absolute;
                                right: 0px;
                                content: '';
                                width: 1px;
                                background-color: #d1dae1;
                                height: 100%;
                                opacity: 0.2;
                            }
                            &:last-child {
                                &::after {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
        ::v-deep {
            .custom-video-component {
                margin-left: auto;
                margin-right: auto;
                .video-control-wrapper {
                    margin-left: -40px;
                    margin-top: -40px;
                    .video-control-icon {
                        background-color: transparent;
                        font-size: 10px;
                        width: 80px;
                        height: 80px;
                        border: solid #ffffff 3px;
                    }
                    img {
                        &.play-icon {
                            width: 25px;
                            height: 25px;
                            margin-left: 3px;
                        }
                    }
                    .video-control-text {
                        display: none;
                    }
                }
            }
        }
    }
}
</style>
