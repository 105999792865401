<template>
    <div class="renew">
        <div class="container">
            <div class="blue-light">
                <div class="row">
                    <div class="col-md-6 col-sm-12 text-center renew-order">
                        <h3>Blue Light Blocking Lenses</h3>
                        <p>BluDefend™ blue light blocking lenses shield your eyes against harmful blue light and UV rays</p>
                        <button class="btn btn-primary">Learn more</button>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <img :src="formatAssetUrl('images/mobile-blueLight.png')" alt="blue-light" class="mobile-renew-img" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import formatImageMixin from '@/mixins/image-url-formatter';
export default {
    mixins: [formatImageMixin]
}
</script>

<style lang="scss" scoped>
    @import '~/assets/scss/variables/breakpoints.scss';

    .renew {
        margin-bottom: 48px;
    }

    @media (max-width: $breakpoint3) {
        .renew {
            margin-top: 0px;
            .blue-light {
                margin-top: 0px;
            }
        }
    }
</style>
