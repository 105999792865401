<template>
    <!-- Start Partner Area -->
    <div class="banners">
        <div class="owl-carousel owl-theme" :class="selectedName">
            <ssr-carousel
                @change="onChange"
                :loop="true"
                :slides-per-page="5"
                :peek="110"
                :responsive="[
                    {
                        maxWidth: 1060,
                        slidesPerPage: 1
                    }
                ]"
            >
                <div
                    class="slide"
                    v-for="(banner, index) in banners"
                    :key="banner.id"
                    :class="{ selected: selectedIndex === index }"
                >
                    <div class="banner-block">
                        <a @click.prevent="onChange({ index })">
                            <img
                                :src="formatAssetUrl('images/homepage/' + banner.name + '.svg')"
                                :alt="banner.name"
                                :class="banner.name"
                            />
                        </a>
                    </div>
                </div>
            </ssr-carousel>
        </div>
    </div>
    <!-- End Partner Area -->
</template>

<script>
import formatImageMixin from '@/mixins/image-url-formatter';

export default {
    mixins: [formatImageMixin],
    name: 'Banner',
    data() {
        return {
            selectedIndex: 0,
            banners: [
                {"id": "1", "name":"purewow", "alt": "purewow"},
                {"id": "2", "name":"buzzfeed", "alt": "buzzfeed"},
                {"id": "3", "name":"newsweek", "alt": "newsweek"},
                {"id": "4", "name":"cnet", "alt": "cnet"},
                {"id": "5", "name":"fatherly", "alt": "fatherly"},
            ]
        }
    },
    computed: {
        selectedName() {
            try {
                return this.banners[this.selectedIndex].name;
            } catch (e) {
                return '';
            }
        }
    },
    methods: {
        onChange(e) {
            console.log(e);
            this.selectedIndex = e.index;
            this.$emit('change', e.index);
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/breakpoints.scss';

.slide {
    .banner-block {
        img {
            opacity: .4;
            &:hover {
                opacity: .85;
            }
        }
    }
    &.selected {
        .banner-block {
            img {
                opacity: 1
            }
        }
    }
}

@media (min-width: $breakpoint2) {
    .banners {
        ::v-deep {
            .ssr-carousel-track {
                gap: 70px;
            }
        }
        .slide {
            width: auto !important;
            margin-right: 0px;
        }
    }
}

@media (max-width: $breakpoint2) {
    .banners {
        margin-left: -15px;
        margin-right: -15px;
    }
}
</style>
