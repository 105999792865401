<template>
    <FeaturedCategoryBlock link="hyannis-sunglasses" class="hyannis-homepage-block">
        <h6>Hyannis sunglasses</h6>
        <template #link>
            <nuxt-link to="/hyannis-sunglasses">Shop now</nuxt-link>
        </template>
    </FeaturedCategoryBlock>
</template>

<script>
import FeaturedCategoryBlock from '@/components/landing-page/home/FeaturedCategoryBlock';
export default {
    components: {
        FeaturedCategoryBlock
    }
};
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/base-url.scss';
@import '~/assets/scss/variables/breakpoints.scss';

.hyannis-homepage-block {
    background-image: url($baseAssetUrl+'images/homepage/hyannis-block2.png');
    background-size: cover;
    background-position: center;
    ::v-deep {
        .block-text-content {
            width: 55%;
        }
    }
}

@media (max-width: $breakpoint2) {
    .hyannis-homepage-block {
        ::v-deep {
            .block-text-content {
                width: 75%;
            }
        }
    }
}
</style>
