<template>
    <div class="categories">
        <div class="custom-container">
            <div class="block contact-lenses-hp">
                <img
                    :src="formatAssetUrl('images/homepage/mobile-contacts.png')"
                    class="product-icon hidden-desktop"
                    alt="contact lens box"
                />
                <div class="block-text-content inline-links-section">
                    <h6>Contact Lenses</h6>
                    <p>Skip the doctor's office, and save up to 40% on your contacts</p>
                    <nuxt-link to="/contact-lenses"
                        ><button type="button" class="btn btn-outline outline-thin outline-gray mr-2">Browse</button></nuxt-link
                    >
                    <a href="/profile/order-history" @click.prevent="onClickReorderLenses" class="ml-2 hidden-mobile">
                        <button class="btn btn-outline outline-thin outline-gray">Reorder</button>
                    </a>
                </div>
                <img :src="formatAssetUrl('images/angle-right.svg')" alt="right arrow" class="arrow-right" />
            </div>
            <div class="block eyewear-hp" :class="{ 'is-hover-sunglasses': hoveringSunglasses }">
                <img
                    :src="formatAssetUrl('images/homepage/mobile-glasses.png')"
                    class="product-icon hidden-desktop"
                    alt="glasses"
                />
                <div class="block-text-content">
                    <h6 class="hidden-mobile">Eyewear</h6>
                    <h6 class="hidden-desktop">Glasses</h6>
                    <p>Stylish frames for prescription, non-Rx, blue light blocking, or readers</p>
                    <nuxt-link to="/glasses" class="d-inline-block"
                        ><button class="btn btn-outline outline-thin outline-gray mr-2">Eyeglasses</button></nuxt-link
                    >
                    <nuxt-link to="/sunglasses" class="d-inline-block hidden-mobile">
                        <button
                            class="btn btn-outline outline-thin outline-gray mx-2 btn-sunglasses"
                            @mouseover="onHoverSunglasses"
                            @mouseout="onUnhoverSunglasses"
                        >
                            Sunglasses
                        </button>
                    </nuxt-link>
                </div>
                <img :src="formatAssetUrl('images/angle-right.svg')" alt="right arrow" class="arrow-right" />
            </div>
            <div class="block hidden-desktop">
                <img
                    :src="formatAssetUrl('images/homepage/mobile-sunglasses.png')"
                    alt="sunglasses"
                    class="product-icon hidden-desktop"
                />
                <div class="block-text-content">
                    <h6>Sunglasses</h6>
                    <p>Stylish sunglasses for prescription, non-Rx or readers.</p>
                    <nuxt-link to="/sunglasses" class="d-inline-block"
                        ><button class="btn btn-outline outline-thin outline-gray">Sunglasses</button></nuxt-link
                    >
                </div>
                <img :src="formatAssetUrl('images/angle-right.svg')" alt="right arrow" class="arrow-right" />
            </div>
            <div class="block lens-replacement-hp">
                <img
                    :src="formatAssetUrl('images/homepage/mobile-lens-replacement.png')"
                    alt="replacement lenses for eyewear"
                    class="product-icon hidden-desktop"
                />
                <div class="block-text-content">
                    <h6>Prescription Lens Replacement</h6>
                    <p>New premium prescription lenses for your existing glasses or sunglasses</p>
                    <p><strong>Starting at $64</strong></p>
                    <nuxt-link to="/lens-replacement/prescription-lens-replacement" class="d-inline-block"
                        ><button class="btn btn-outline outline-thin outline-gray mr-2">Learn more</button></nuxt-link
                    >
                </div>
                <img :src="formatAssetUrl('images/angle-right.svg')" alt="right arrow" class="arrow-right" />
            </div>
            <div class="block diy-hp">
                <img
                    :src="formatAssetUrl('images/homepage/mobile-diy.png')"
                    alt="replacement sunglass lenses"
                    class="darken product-icon hidden-desktop"
                />
                <div class="block-text-content inline-links-section">
                    <h6>DIY Lens Replacement</h6>
                    <p>Premium non-rx sunglass lenses for <nuxt-link to="/oakley-lens-replacement">Oakley</nuxt-link>, <nuxt-link to="/ray-ban-lens-replacement">Ray-Ban</nuxt-link> or <nuxt-link to="/mauijim-lens-replacement">Maui Jim</nuxt-link> shipped to your door, easy to install</p>
                    <p><strong>Starting at ${{ diyLensesStartingPrice }}</strong></p>
                    <nuxt-link to="/diy-lens-replacement" class="d-inline-block"
                        ><button class="btn btn-outline outline-thin outline-gray">Browse all</button></nuxt-link
                    >
                </div>
                <img :src="formatAssetUrl('images/angle-right.svg')" alt="right arrow" class="arrow-right" />
            </div>
        </div>
        <img :src="formatAssetUrl('images/homepage/sunglasses.jpg?v=1')" class="preload" alt="sugnlasses image" aria-hidden="true">
    </div>
</template>

<script>
import formatImageMixin from '@/mixins/image-url-formatter';
export default {
    mixins: [formatImageMixin],
    data() {
        return {
            hoveringSunglasses: false
        };
    },
    computed: {
        diyLensesStartingPrice() {
            return this.$store.state.diyLenses.diyLensStartingPrice;
        },
        user() {
            return this.$store.state.auth.currentUser;
        }
    },
    methods: {
        onHoverSunglasses() {
            this.hoveringSunglasses = true;
        },
        onUnhoverSunglasses() {
            this.hoveringSunglasses = false;
        },
        onClickReorderLenses() {
            if (this.user?.customer) {
                this.$router.push('/profile/order-history');
                return;
            }

            this.$store.dispatch('setShowReorderLogin', true);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/breakpoints.scss';
@import '~/assets/scss/variables/color.scss';
@import '~/assets/scss/variables/base-url.scss';
.categories .block img.arrow-right {
    display: none;
}

.categories {
    padding-top: 20px;
    .hidden-desktop {
        display: none !important;
    }
    .block {
        border-radius: 10px;
    }
}

.categories .block img.hidden-desktop {
    display: none;
}
.block-text-content {
    color: $color-grey-text;
    h6 {
        color: $color-black;
    }
}

.contact-lenses-hp {
    background-image: url($baseAssetUrl+'images/homepage/contacts.jpg?v=1');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left center;
}

.eyewear-hp {
    background-image: url($baseAssetUrl+'images/homepage/glasses.jpg?v=1');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right center;
    &.is-hover-sunglasses {
        background-image: url($baseAssetUrl+'images/homepage/sunglasses.jpg?v=1');
    }
}

.preload {
    position: absolute;
    width: 1px;
    height: 1px;
    opacity: 0;
    pointer-events: none;
}

@media (min-width: $minBreakpoint3) {
    .categories .block .btn {
        height: 48px;
    }

    .custom-container {
        display: flex;
        flex-wrap: wrap;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
    }
    .categories {
        .block {
            min-height: 300px;
            display: inline-flex;
            align-items: center;
            text-align: left;
            h6 {
                display: block;
                max-width: 340px;
                font-weight: 900;
                font-size: 24px;
                line-height: 1.2em;
            }
            &.lens-replacement-hp {
                h6 {
                    max-width: 315px;
                }
            }
            img {
                max-height: 220px;
                margin: 0px;
            }
            &.contact-lenses-hp {
                background-color: #f8f8f8;
                .block-text-content {
                    margin-left: auto;
                    padding-right: 30px;
                    p {
                        max-width: 320px;
                    }
                }
            }
            &.eyewear-hp {
                background-color: #f7f8fa;
                flex-direction: row-reverse;
                background-position-x: right;
                p {
                    margin-left: 0px;
                }
                .block-text-content {
                    margin-right: auto;
                    padding-left: 30px;
                }
                .btn-sunglasses {
                    margin-left: 0px !important;
                }
            }
            &.diy-hp {
                background-color: #e2edf3;
                background-image: url($baseAssetUrl+'images/homepage/diy-03.jpg?v=1');
                background-size: contain;
                background-repeat: no-repeat;
                background-position-y: center;
                .block-text-content {
                    margin-right: auto;
                    padding-left: 20px;
                    max-width: 300px;
                }
                p {
                    max-width: 350px;
                }
                a {
                    color: #000000;
                    text-decoration: underline;
                    &:hover {
                        color: rgba(0,0,0,.8);
                        text-decoration: none;
                    }
                }
            }
            &.lens-replacement-hp {
                min-height: 330px;
                background-color: #e8e8e8;
                flex-direction: row-reverse;
                background-image: url($baseAssetUrl+'images/homepage/lr-rx.jpg?v=1');
                background-size: cover;
                background-position: right center;
                background-repeat: no-repeat;
                .block-text-content {
                    margin-left: auto;
                    padding-right: 30px;
                    p {
                        margin-left: 0px;
                        margin-right: 0px;
                    }
                }
            }
        }
    }
}


@media (min-width: $breakpoint1) {
    .categories {
        .block {
            &.eyewear-hp {
                background-position-x: 40px;
                .block-text-content {
                    max-width: 55%;
                }
            }
            &.diy-hp {
                background-position-x: 40px;
            }
        }
    }
}

@media (min-width: $desktopBreakpoint4) {
    .categories {
        .block {
            min-height: 300px;
            img {
                max-height: 220px;
            }

            &.contact-lenses-hp {
                background-position-x: -25px;
                .block-text-content {
                    padding-right: 15px;
                    p {
                        max-width: 320px;
                    }
                }
            }

            &.eyewear-hp {
                background-position-x: right;
                .block-text-content {
                    padding-left: 20px;
                    max-width: 55%;
                }
                .btn-sunglasses {
                    margin-left: 0.5rem !important;
                }
            }
            &.diy-hp {
                background-position: right center;
                .block-text-content {
                    padding-left: 20px;
                    max-width: 50%;
                    p {
                        max-width: 350px;
                    }
                }
            }
            &.lens-replacement-hp {
                min-height: 330px;
                background-position: right bottom;
                .block-text-content {
                    padding-right: 20px;
                }
            }
        }
    }
}

@media (min-width: $desktopBreakpoint1) {
    .categories {
        .block {
            min-height: 380px;

            img {
                max-height: 280px;
            }

            &.contact-lenses-hp {
                .block-text-content {
                    padding-right: 40px;
                    p {
                        max-width: 350px;
                    }
                }
            }

            &.lens-replacement-hp {
                .block-text-content {
                    p {
                        max-width: 340px;
                    }
                }
                h6 {
                    max-width: 350px;
                }
            }

            &.eyewear-hp {
                .block-text-content {
                    padding-left: 40px;
                }
            }
            &.diy-hp {
                background-position: right center;
                .block-text-content {
                    padding-left: 40px;
                    max-width: none;
                    p {
                        max-width: 350px;
                    }
                }
            }
            &.lens-replacement-hp {
                min-height: 380px;
                .block-text-content {
                    padding-right: 40px;
                }
            }
        }
    }
}

@media (min-width: $desktopBreakpoint0) {
    .categories {
        .block {
            min-height: 460px;

            h6 {
                font-size: 32px;
            }

            img {
                max-height: 370px;
            }

            &.contact-lenses-hp {
                background-position-x: 0px;
                .block-text-content {
                    padding-right: 65px;
                }
            }

            &.eyewear-hp {
                .block-text-content {
                    padding-left: 65px;
                }
            }
            &.diy-hp {
                .block-text-content {
                    padding-left: 65px;
                }
            }
            &.lens-replacement-hp {
                min-height: 460px;
                .block-text-content {
                    padding-right: 65px;
                }
            }
        }
    }
}

@media (max-width: 1600px) {
    .categories {
        .block {
            &.lens-replacement-hp {
                background-position-x: -40px;
            }
        }
    }
}

@media (max-width: $desktopBreakpoint1) {
    .block-text-content {
        font-size: 16px;
        p {
            font-size: 1em;
        }
        .btn {
            font-size: 16px;
        }
    }
    .categories {
        .block {
            &.lens-replacement-hp {
                background-position-x: 0px;
                .block-text-content {
                    padding-right: 15px;
                    h6 {
                        max-width: 320px;
                    }
                    p {
                        max-width: 320px;
                    }
                }
            }
            &.contact-lenses-hp {
                .block-text-content {
                    padding-right: 15px;
                    h6 {
                        max-width: 320px;
                    }
                    p {
                        max-width: 320px;
                    }
                }
            }
        }
    }
}

@media (max-width: $desktopBreakpoint2) {
    .categories {
        .block {
            &.lens-replacement-hp {
                background-position-x: -30px;
            }
        }
    }
}

@media (max-width: $desktopBreakpoint3) {
    .block-text-content {
        font-size: 15px;
        max-width: 50%;
        p {
            font-size: 1em;
        }
        .btn {
            font-size: 15px;
        }
    }

    .categories {
        .block {
            &.lens-replacement-hp {
                background-position-x: -40px;
            }
        }
    }
}

@media (max-width: $breakpoint1) {
    .categories {
        .block {
            &.eyewear-hp {
                background-position-x: -50px;
            }
            &.contact-lenses-hp {
                background-position-x: -90px;
            }
            &.diy-hp {
                background-position-x: 50px;
            }
            &.lens-replacement-hp {
                background-position-x: -100px;
            }
        }
    }
}

@media (max-width: $breakpoint2) {
    .categories {
        border-top: solid $color-grey-border 1px;
        padding-top: 0px;

        .custom-container {
            padding-left: 0px !important;
            padding-right: 0px !important;
        }
        .block {
            position: relative;
            display: flex;
            background-color: $bg-light-grey;
            padding: 20px;
            align-items: center;
            border-bottom: solid $color-grey-border 1px;
            text-align: left;
            &.hidden-desktop {
                display: flex !important;
            }
            p {
                display: none;
            }
            a {
                position: absolute;
                opacity: 0;
                top: 0px;
                right: 0px;
                left: 0px;
                bottom: 0px;
            }
            .product-icon {
                max-width: 80px;
                margin: 0px;
            }
            h6 {
                font-size: 20px;
                padding-left: 10px;
                margin-bottom: 0px;
                line-height: 1.2em;
            }
            img {
                &.arrow-right {
                    display: block;
                    margin: 0px;
                    margin-left: auto;
                }
            }
            &.lens-replacement-hp {
                img {
                    max-width: 100px;
                    margin-left: 0px;
                    margin-right: 0px;
                    &.arrow-right {
                        margin-left: auto;
                    }
                }
            }
            &.diy-hp {
                img {
                    max-width: 100px;
                    margin-left: 0px;
                    margin-right: 0px;
                    &.arrow-right {
                        margin-left: auto;
                    }
                }
            }
        }
    }

    .contact-lenses-hp {
        background-image: none;
    }

    .eyewear-hp {
        background-image: none;
    }
}

@media (max-width: $breakpoint2) {
    .categories {
        .hidden-mobile {
            &.product-icon {
                display: none !important;
            }
        }
        .hidden-desktop {
            &.product-icon {
                display: none;
                max-width: 100px;
            }
        }
        h6 {
            &.hidden-desktop {
                display: block !important;
            }
        }
        h6 {
            &.hidden-mobile {
                display: none !important;
            }
        }

        .block {
            border-radius: 0px;
            padding: 5px 20px;
            min-height: 80px;
            &.contact-lenses-hp {
                .block-text-content {
                    margin-left: 30px;
                }
            }
            &.diy-hp {
                background-image: none;
                .block-text-content {
                    margin-left: 30px;
                    max-width: 360px;
                }
            }
            &.lens-replacement-hp {
                background-image: none;
            }
        }
    }
}

@media (max-width: $breakpoint3) {
    .categories {
        .hidden-desktop {
            &.product-icon {
                display: block !important;
            }
        }

        .block {
            &.contact-lenses-hp {
                .block-text-content {
                    margin-left: 0px;
                }
            }
            &.diy-hp {
                background-image: none;
                .block-text-content {
                    margin-left: 0px;
                    max-width: none;
                }
            }
            .block-text-content {
                max-width: 100%;
            }
        }
    }
}
</style>
