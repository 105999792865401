<template>
    <!-- Start Main Banner Area -->
    <div class="main-banner main-banner-three item-bg4">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="custom-container hero-container">
                    <div class="main-banner-content">
                        <h1 class="text-black bolder">See clearly for less</h1>
                        <p class="text-black mt-2 mt-sm-1 semi-bold mb-3">
                            Shop name-brand contacts, eyewear and lens replacement for your frames
                        </p>
                        <div class="mt-3 mt-sm-5">
                            <a href="/profile/order-history" class="btn btn-primary mr-3 home-button" id="LDReorderLensesLink"
                                >Reorder my contacts</a>
                            <nuxt-link to="/contact-lenses" class="btn btn-outline outline-dark"
                                >I'm new here</nuxt-link
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Main Banner Area -->
</template>

<script>
import reorderLensesEvent from '@/utils/reorder-lenses-event';

export default {
    mounted() {
        reorderLensesEvent('LDReorderLensesLink', this.$store, this.$router).addListener();
    },
    destroyed() {
        reorderLensesEvent('LDReorderLensesLink', this.$store, this.$router).removeListener();
    }
};
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables/breakpoints.scss';

.main-banner {
    .hero-container {
        .main-banner-content {
            h1 {
                line-height: 1.05em;
            }
            p {
                line-height: 1.2em;
            }
        }
    }
}
@media (max-width: $desktopBreakpoint3) {
    .main-banner {
        &.main-banner-three {
            height: 438px;
        }

        .hero-container {
            .main-banner-content {
                h1 {
                    font-size: 50px;
                }
                p {
                    font-size: 20px;
                    line-height: 1.2em;
                }
            }
        }
    }
}
</style>
