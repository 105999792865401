var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"testimonials-area",attrs:{"data-jarallax":"{\"speed\": 0.3}"}},[_c('h3',[_vm._v("We don't like to talk about ourselves")]),_vm._v(" "),_c('p',{staticClass:"mt-3"},[_vm._v("But we don't mind if our customers do")]),_vm._v(" "),_c('div',{staticClass:"testimonials-slides owl-carousel owl-theme"},[_c('ssr-carousel',{attrs:{"loop":true,"peek-right":40,"slides-per-page":3,"responsive":[
                {
                    maxWidth: 1025,
                    slidesPerPage: 1,
                    peekRight: 40
                }
            ]}},_vm._l((_vm.items),function(item,index){return _c('div',{key:index + 1,staticClass:"slide"},[_c('div',{staticClass:"single-testimonials"},[_c('img',{attrs:{"src":_vm.formatAssetUrl('images/stars.svg')}}),_vm._v(" "),_c('p',[_vm._v(_vm._s(item.feedback))]),_vm._v(" "),_c('div',{staticClass:"client-info"},[_c('h6',[_vm._v(_vm._s(item.name))]),_vm._v(" "),_c('div',{staticClass:"verified"},[_c('img',{attrs:{"src":_vm.formatAssetUrl('images/verify.svg')}}),_vm._v(" Verified customer\n                        ")])])])])}),0)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }